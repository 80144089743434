function Login() {
}
Login.prototype.listenSubmitEvent = function () {
    var submitBtn = $("#submit-btn");
    submitBtn.click(function (event) {
        event.preventDefault();
        var telephone = $("input[name='telephone']").val();
        var password = $("input[name='password']").val();
        var remember = $("input[name='remember']").val();

        var url = '/account/login/';
        myajax.post({
            'url': url,
            'data': {
                'telephone': telephone,
                'password':password,
                'remember':remember
            },
            'success': function (result) {
                if(result['code'] === 200){
                    window.location.href='/cms/index/';
                }
            }
        });
    });
};

Login.prototype.run = function () {
    var self = this;
    self.listenSubmitEvent();
};


$(function () {
    var login = new Login();
    login.run();
});